<template>
    <modal
        id="requestReset"
        :title="title == '' ? $t('modal.reset.heading') : title"
        type="general"
    >
        <form
            id="password-reset"
            @submit.prevent="resetRequest"
        >
            <p class="sm mb-4">
                {{ text == '' ? $t('modal.reset.introduceEmailText') : text }}
            </p>

            <div
                class="form-group w100"
                :class="{ error: isError }"
            >
                <label>{{ $t('form.fields.label.email_address') }}</label>
                <input
                    v-model="input.email"
                    :placeholder="$t('form.fields.placeholder.email_address')"
                    :disabled="reseted"
                    type="text"
                    class="form-control"
                    name="email"
                >
                <span
                    v-if="errors.email"
                    class="error-message"
                >{{
                    errors.email
                }}</span>
                <span
                    v-if="errors.reset"
                    class="error-message"
                >{{
                    errors.reset
                }}</span>
            </div>
            <b-button
                v-if="!reseted"
                variant="primary"
                class="fill lg reset mt-5"
                :disabled="disabled || reseted"
                type="submit"
            >
                {{ $t('modal.reset.sendButton') }}
            </b-button>
        </form>
        <div
            v-if="!isLogged"
            class="member d-flex"
        >
            <p class="sm">
                {{ $t('modal.reset.backText') }}
            </p>
            <p
                class="login sm-sbold"
                @click="showLoginPopup()"
            >
                {{ $t('modal.reset.backLoginText') }}
            </p>
        </div>
    </modal>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal.vue';
    export default {
        name: 'RequestResetPasswordModal',
        components: {
            Modal,
        },
        data() {
            return {
                input: {
                    email: '',
                },
                title: '',
                text: '',
                reseted: false,
                disabled: false,
                errors: {},
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            ...mapState('general', ['modal']),
            ...mapState('auth', ['inputErrors', 'resetEmail']),
            isError() {
                return this.errors.email || this.errors.reset;
            },
        },
        methods: {
            ...mapMutations('general', ['setModal']),
            ...mapActions('auth', ['requestResetToken']),
            async resetRequest() {
                this.errors = {};
                this.errors = formValidations(this.input);
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.disabled = true;
                await this.requestResetToken({ email: this.input.email });

                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }

                this.resetSent();
            },
            resetSent() {
                this.title = this.$t('modal.reset.sentTitle');
                this.text = this.$t('modal.reset.sentText');
                this.reseted = true;
            },
            initialize() {
                this.title = this.$t('modal.reset.heading');
                this.text = this.$t('modal.reset.introduceEmailText');
                this.reseted = false;
                this.errors = {};
                this.disabled = false;
                this.input.email = this.resetEmail;
            },
            closeModal() {
                this.initialize();
                this.setModal({
                    show: false,
                    type: 'requestReset',
                });
            },
            showLoginPopup() {
                this.closeModal();
                setTimeout(() => {
                    this.setModal({
                        show: true,
                        type: 'login',
                    });
                }, 200);
            },
        },
        mounted() {
            this.input.email =
                Object.keys(this.modal.data).length > 0 ? this.modal.data.resetEmail : '';
        },
        watch: {
            input: {
                handler() {
                    this.disabled = false;
                },
                deep: true,
            },
        },
    };
</script>
